import axios from 'axios';
import store from '@/store';
import config from '@/config/base.config.json';
import Vue from 'vue';
import debounce from 'lodash/debounce'
 
export class Interceptors {
  public instance: any;
  public $message = Vue.prototype.$Message;
 
  constructor() {
    // 创建axios实例
    this.instance = axios.create({
      // 请求路径 
      baseURL : config.apiUrl,
      // 允许携带cookie
      withCredentials : true,
      // 请求超时时间
      timeout : 1000 * 12,
      headers: {
        'Cache-Control': 'no-cache',
        'local': store.state.lang
      },
    });
    // 初始化拦截器
    this.initInterceptors();
  }
   
  // 为了让http.ts中获取初始化好的axios实例
  public getInterceptors() {
    return this.instance;
  }
 
 
  // 初始化拦截器
  public initInterceptors() {
    
    // 设置post请求头
    this.instance.defaults.headers.post['Content-Type'] = 'application/json';

    /**
    * 请求拦截器
    * 每次请求前，如果存在token则在请求头中携带token
    */
    this.instance.interceptors.request.use(
      (config:any) => {  
        if(config.headers.showLoading){
          store.commit('changePromisings','add');
        }
        if(config.headers.responseType){
          config.responseType = config.headers.responseType
        }
        return config;
      },
      (error:any) => {
        console.log(error);
        store.commit('changePromisings','del');
      },
    ); 
 
    // 响应拦截器
    this.instance.interceptors.response.use(
      // 请求成功
      (res:any) => {
        store.commit('changePromisings','del');
        if (res.status === 200) {
          return Promise.resolve(res.data);
        } else {
          this.errorHandle(res);
          return Promise.reject(res.data);
        }
      },      
      // 请求失败
      (error:any) => {
        store.commit('changePromisings','del');
        const {response} = error;
        if (response) {
          // 请求已发出，但是不在2xx的范围
          this.errorHandle(response);
          return Promise.reject(response.data);
        } else {
          // 处理断网的情况
          // this.$message.error('网络连接异常,请稍后再试!');
          this.$message.error('Network connection is abnormal, please try again later!');
        }
      });
  } 
 
  /**
  * http握手错误
  * @param res 响应回调,根据不同响应进行不同操作
  */
  private errorHandle(res: any) {
    // 状态码判断
    switch (res.status) {
      case 401:
        loginExpireNotice(toLogin)
        break;
      case 403:
        this.$message.error('No Access');
        break;
      case 404:
        this.$message.error('Resources Are Not Available');
        break;
      default:
        this.$message.error('Connection Error');
    }
  }

}
// 多个接口同时请求单位时间内都返回登录失效进行节流处理（避免连续弹出未登录弹框）
const loginExpireNotice = debounce((cb:any) => {
  cb && cb();
}, 1000, {
  leading: true, // 延迟开始前调用
  trailing: false // 延迟结束后不调用
});
/**
 * 跳转登录页
 */
const toLogin = () => {
  Vue.prototype.$Message.error('Please Login');
  store.commit('loginOut');
}