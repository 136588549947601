import { RouteConfig } from 'vue-router';

/**
 * 菜单路由
 * meta信息解释
 * menuName 对应的语言配置文件当中的键值
 * iconClass 图标类名
 * isMenu 是否是菜单
 * rightPath 菜单点亮路径,格式为 name.name 第一个name表示一级点亮的菜单，第二个menuName表示当前菜单
 * resourceUrl 组件地址 (不需要待后缀 .vue)
 * roles 角色 
 */
// 主路由
const routes: RouteConfig[] = [  
  //首页展示
  {
    path: '/index',
    name: 'home',    
    meta : {
      menuName : 'homeShow', //lang.config.js 里面的语言键值
      iconClass : 'md-home',
      rightPath : '',
      isMenu : true,  
      resourceUrl: 'main/home/HomeView',
    }
  },
  //服务器
  {
    path: '/service',
    name : 'service',    
    redirect: { name: 'serviceHome' },
    meta : {
      menuName : 'service', //lang.config.js 里面的语言键值
      iconClass : 'logo-buffer',
      rightPath : 'service',
      isMenu : true,  
      resourceUrl: 'main/service/ServiceView',
    },
    children : [
      {
        //服务器 - 首页
        path: '/service/index',
        name: 'serviceHome',        
        meta : {
          menuName : 'home', //lang.config.js 里面的语言键值
          rightPath : 'service.serviceHome',
          isMenu : true, 
          resourceUrl: 'main/service/Index/IndexView',
        }
      },
      //.此处需动态插入路由
    ]
  },
  //分组管理
  {
    path: '/group',
    name: 'group',
    meta : {
      menuName : 'groupManage', //lang.config.js 里面的语言键值
      iconClass : 'md-medical',
      rightPath : 'group',
      isMenu : true, 
      resourceUrl: 'main/group/GroupView',
    },
    children:[
      //服务器分组
      {
        path: '/group/service',
        name: 'serviceGroup',
        meta : {
          menuName : 'serviceGroup', //lang.config.js 里面的语言键值
          rightPath : 'group.serviceGroup',
          isMenu : true, 
          resourceUrl: 'main/group/serviceGroup/serviceGroup',
        },
      },
      //PDU分组
      {
        path: '/group/pdu',
        name: 'pduGroup',
        meta : {
          menuName : 'pduGroup', //lang.config.js 里面的语言键值
          rightPath : 'group.pduGroup',
          isMenu : true, 
          resourceUrl: 'main/group/pduGroup/pduGroup',
        },
      },
      //插孔分组
      {
        path: '/group/plug',
        name: 'plugGroup',
        meta : {
          menuName : 'plugGroup', //lang.config.js 里面的语言键值
          rightPath : 'group.plugGroup',
          isMenu : true, 
          resourceUrl: 'main/group/plugGroup/plugGroup',
        },
      },
      //风机分组
      {
        path: '/group/fan',
        name: 'fanGroup',
        meta : {
          menuName : 'fanGroup', //lang.config.js 里面的语言键值
          rightPath : 'group.fanGroup',
          isMenu : true, 
          resourceUrl: 'main/group/fanGroup/fanGroup',
        },
      },
      //水泵分组
      {
        path: '/group/water',
        name: 'waterGroup',
        meta : {
          menuName : 'waterGroup', //lang.config.js 里面的语言键值
          rightPath : 'group.waterGroup',
          isMenu : true, 
          resourceUrl: 'main/group/waterGroup/waterGroup',
        },
      },
    ]
  },
  //划拨管理
  {
    path: '/transfer',
    name: 'transfer',
    meta : {
      menuName : 'transferMange', //lang.config.js 里面的语言键值
      iconClass : 'md-mail-open',
      rightPath : 'transfer',
      isMenu : true, 
      resourceUrl: 'main/transfer/transferView',
    },
    children:[
      // 平台管理员只操作服务器划拨,其他划拨给其他角色
      //服务器划拨
      {
        path: '/transfer/service',
        name: 'serviceTransfer',
        meta : {
          menuName : 'serviceTransfer', //lang.config.js 里面的语言键值
          rightPath : 'transfer.serviceTransfer',
          isMenu : true, 
          resourceUrl: 'main/transfer/serviceTransfer/serviceTransfer',
          roles : ['platform_admin']
        },
      },
      //PDU划拨
      {
        path: '/transfer/pdu',
        name: 'pduTransfer',
        meta : {
          menuName : 'pduTransfer', //lang.config.js 里面的语言键值
          rightPath : 'transfer.pduTransfer',
          isMenu : true, 
          resourceUrl: 'main/transfer/pduTransfer/pduTransfer',
          roles : ['merchant','merchant_admin','merchant_guest']
        },
      },
      //插孔划拨
      {
        path: '/transfer/plug',
        name: 'plugTransfer',
        meta : {
          menuName : 'plugTransfer', //lang.config.js 里面的语言键值
          rightPath : 'transfer.plugTransfer',
          isMenu : true, 
          resourceUrl: 'main/transfer/plugTransfer/plugTransfer',
          roles : ['merchant','merchant_admin','merchant_guest']
        },
      },
      //风机划拨
      {
        path: '/transfer/fan',
        name: 'fanTransfer',
        meta : {
          menuName : 'fanTransfer', //lang.config.js 里面的语言键值
          rightPath : 'transfer.fanTransfer',
          isMenu : true, 
          resourceUrl: 'main/transfer/fanTransfer/fanTransfer',
          roles : ['merchant','merchant_admin','merchant_guest']
        },
      },
      //水泵划拨
      {
        path: '/transfer/water',
        name: 'waterTransfer',
        meta : {
          menuName : 'waterTransfer', //lang.config.js 里面的语言键值
          rightPath : 'transfer.waterTransfer',
          isMenu : true, 
          resourceUrl: 'main/transfer/waterTransfer/waterTransfer',
          roles:['merchant','merchant_admin','merchant_guest']
        },
      },
    ]
  },
  //运维中心
  {
    path: '/operations',
    name: 'operations',
    meta : {
      menuName : 'operations-center', //lang.config.js 里面的语言键值
      iconClass : 'md-list-box',
      rightPath : 'operations',
      isMenu : true, 
      resourceUrl: 'main/operationCenter/OperationCenterView',
    },
    children : [
      //运维中心-告警管理
      {
        path: '/operations/warning',
        name: 'operationsWarning',
        meta : {
          menuName : 'warn-management', //lang.config.js 里面的语言键值
          rightPath : 'operations.operationsWarning',
          isMenu : true, 
          resourceUrl: 'main/operationCenter/warningManagement/index',
        },
      },
      //运维中心-耗能统计
      {
        path: '/operations/energyConsumptionStatistics',
        name: 'energyConsumptionStatistics',
        meta : {
          menuName : 'energyConsumptionStatistics', //lang.config.js 里面的语言键值
          rightPath : 'operations.energyConsumptionStatistics',
          isMenu : true, 
          resourceUrl: 'main/operationCenter/energyConsumptionStatistics/index',
          //roles : ['platform_admin','merchant'],
        },
      },
    ]
  },
  //安全中心
  {
    path: '/security',
    name: 'security',
    meta : {
      menuName : 'security-center', //lang.config.js 里面的语言键值
      iconClass : 'ios-water',
      rightPath : 'security',
      isMenu : true, 
      resourceUrl: 'main/safecenter/SafecenterView',
    },
    children : [
      //安全中心-安全警告
      {
        path: '/security/warning',
        name: 'securityWarning',
        meta : {
          menuName : 'safety-forecas', //lang.config.js 里面的语言键值
          rightPath : 'security.securityWarning',
          isMenu : true, 
          resourceUrl: 'main/safecenter/safeWarning/safeWarningView',
        },
      },
    ]
  },
  //系统设置
  {
    path: '/system',
    name: 'system',
    meta : {
      menuName : 'system-settings', //lang.config.js 里面的语言键值
      iconClass : 'md-settings',
      rightPath : 'system',
      isMenu : true, 
      resourceUrl: 'main/system/SystemView',
      roles : ['platform_admin','merchant'],//管理员和商户查看系统设置
    },
    children : [
      //系统设置-用户管理
      {
        path: '/system/userManagement',
        name: 'userManagement',
        meta : {
          menuName : 'user-management', //lang.config.js 里面的语言键值
          rightPath : 'system.userManagement',
          isMenu : true, 
          resourceUrl: 'main/system/userManagement/userManagementView',
        },
      },
      //系统设置-网络设置
      {
        path: '/system/networkSet',
        name: 'networkSet',
        meta : {
          menuName : 'network-settings', //lang.config.js 里面的语言键值
          rightPath : 'system.networkSet',
          isMenu : true,
          resourceUrl: 'main/system/networkSet/networkSetView',
          roles : ['platform_admin'],
        },
      },
      //系统设置-SMTP
      {
        path: '/system/smtp',
        name: 'smtpSet',
        meta : {
          menuName : 'smtp-settings', //lang.config.js 里面的语言键值
          rightPath : 'system.smtpSet',
          isMenu : true,
          resourceUrl: 'main/system/smtpSet/smtpSetView',
        },
      },
      //系统设置-系统信息
      {
        path: '/system/systemInfo',
        name: 'systemInfo',
        meta : {
          menuName : 'system-info', //lang.config.js 里面的语言键值
          rightPath : 'system.systemInfo',
          isMenu : true, 
          resourceUrl: 'main/system/systemInfo/SystemInfoView',
        },
      },
    ]
  },
  //个人中心
  // {
  //   path: '/userInfo',
  //   name: 'userInfo',
  //   meta : {
  //     menuName : 'person-center', //lang.config.js 里面的语言键值
  //     iconClass : 'ios-happy',
  //     rightPath : 'userInfo',
  //     isMenu : true, 
  //     resourceUrl: 'main/personCenter/personCenterView',
  //   },
  // }
];

export default routes;