import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import BaseUrl from './base-url';
import store from '@/store';
import { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err);
};

function onBeforeEach(to: Route, from: Route, next: NavigationGuardNext) {
  if (store.state.userInfo === null && to.name === BaseUrl.LOGIN_ROUTE_NAME) {
    next();
  } else if (store.state.userInfo === null) {
    next({ name: BaseUrl.LOGIN_ROUTE_NAME });
  }  else {
    if(!to.name){
      next({ name: BaseUrl.HOME_ROUTE_NAME });
    } else {
      next()
    }    
  }
}

const loginRouter: RouteConfig[] = [
  //登录
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue')
  },
  //404
  {
    path: "/404", 
    name: 'notFound',
    component: () => import(/* webpackChunkName: "main" */ '../views/notFound/index.vue'),
  }
]

export class MyRouter { 

  static routerCreateFlag = false;
  
  static createRouter() {
    const router = new VueRouter({
      mode: 'hash',
      base: process.env.BASE_URL,
      routes: loginRouter,
    });
    router.beforeEach(onBeforeEach);
    this.initRouter(router);
    return router;
  }

  static initRouter(router: VueRouter) {
    const r: RouteConfig = {
      path: '/main',
      name: 'main',
      component: () => import(/* webpackChunkName: "main" */ '../views/main/MainView.vue'),
      meta : {
        isMenu : true,  
      },
      children: [],
    };
    if (store.state.routers.length > 0) {
      r.children = MyRouter.initTrees(store.state.routers);
    }    
    router.addRoute(r);
  }

  static initTrees(data:any){    
    const arr : RouteConfig[]= []; 
    data.forEach((item:any) => {   
      arr.push({
        path: item.path,
        name: item.name,
        meta : item.meta,
        component: () => import(/* webpackChunkName: "main" */ `../views/${item.meta.resourceUrl}.vue`),
        children: item.children ? MyRouter.initTrees(item.children) : [],
        redirect : item.redirect
      });
    });
    return arr; 
  }
}

const router = MyRouter.createRouter();

/**
 * 初始化路由
 */
export function resetRouter() {
  const newRouter = MyRouter.createRouter();
  (router as any).matcher = (newRouter as any).matcher;
}

export default router;

