import { MutationTree } from 'vuex';
import { RootState } from '@/store/main/state-models';
import { LoginModels } from '@/api/login/login-models';
import BaseUrl from '@/router/base-url';
import router from '@/router';
import State from './state'

/**
 * 清除登录token
 * @param state 
 */
export function cleanLoginToken() {
    router.push({ name: BaseUrl.LOGIN_ROUTE_NAME });
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('unread');
    // sessionStorage.removeItem('serviceOreBoxList');
    sessionStorage.removeItem('tssPubDataDict');
    localStorage.removeItem('routers');
    localStorage.removeItem('serviceOreBoxList');
    State.userInfo = {} as any;
    State.unread = 0;
    State.serviceOreBoxList = [];
    State.routers = [];
    State.tssPubDataDict = null;    
}

const mutations: MutationTree<RootState> = {
    /**
     * 更改请求中接口的个数
     * @param state 
     * @param type 
     */
    changePromisings (state,type) {
        if (type === 'add') {
            state.promisings++;
        } else if (type === 'del') {
            state.promisings--;
        }
        if (state.promisings > 0) {
            state.isLoading = true;
        } else {
            state.isLoading = false;
        }
    },
    /**
     * 设置语言包
     * @param state 
     * @param lang 
     */
    setLang(state, lang: string) {
        state.lang = lang;
        localStorage.setItem('lang', lang);
    },
    /**
     * 设置UserInfo信息
     * @param state 
     * @param userInfo 
     */
    setUserInfo(state, userInfo: LoginModels.UserInfo) {
        state.userInfo = userInfo;
        sessionStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    /**
     * 登出操作
     * @param state 
     */
    loginOut() {
        cleanLoginToken();
    },
    /**
     * 预警未读数量
     */
    changeWarningGwCount(state, unread) {
        state.unread = unread;
        sessionStorage.setItem('unread', unread);
    },
    /**
     * 服务器-矿箱列表
     */
     changeOreBoxList(state,list){
        state.serviceOreBoxList = list;
        localStorage.setItem('serviceOreBoxList', JSON.stringify(list));
    },
    /**
     * 路由列表
     */
    changeRouters(state,data){
        state.routers = data;
        localStorage.setItem('routers', JSON.stringify(data));
    },
    /**
     * 字典
     */
    setDict(state,data){
        state.tssPubDataDict = data;
        sessionStorage.setItem('tssPubDataDict', JSON.stringify(data));
    }

};
export default mutations;
