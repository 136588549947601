import { DirectiveOptions } from "vue";

/* 滚动条 */
export const scrollbar: DirectiveOptions = {
    bind(el, binding) {
        switch (binding.arg) {
            case 'no': //不显示
                el.classList.add("scrollbar-no");
                break;
            case 'all': //都显示
                el.classList.add("scrollbar-all");
                break;
            case 'hind': //隐藏
                el.classList.add("scrollbar-hind");
                break;
            case 'x': //显示x
                el.classList.add("scrollbar-x");
                break;
            case 'y': //显示y
                el.classList.add("scrollbar-y");
                break;
        }
    },
}


