/**
 * 微服务地址
 */
const _pre = process.env.NODE_ENV === 'development' ? '/api' : '/api';
export const APIServer = {
    /**
    * 测试
    */
    Test : _pre + '/test',
    /**
     * 后台服务
     */
    System : _pre,
}

