import { RouteConfig } from 'vue-router';

/**
 * 菜单路由
 * meta信息解释
 * menuName 对应的语言配置文件当中的键值
 * iconClass 图标类名
 * isMenu 是否是菜单
 * rightPath 菜单点亮路径,格式为 name.name 第一个name表示一级点亮的菜单，第二个menuName表示当前菜单
 * resourceUrl 组件地址 (不需要待后缀 .vue)
 * 
 */

// 服务器子路由 - 需要根据矿箱列表动态插入到服务器路由下面
//一级菜单的menuname
const topMenuName = 'service';
const serviceRouters: RouteConfig[] = [  
  //电气系统
  {
    path: '/service/electric',
    name: 'electricSystem',    
    meta : {
      menuName : 'electric-system', //lang.config.js 里面的语言键值
      rightPath : topMenuName + '.' + 'electricSystem',
      isMenu : true, 
      resourceUrl: 'main/service/KX/electricSystem/electricSystemView',
    },
    children : [
      //电气系统-PDU
      {
        path: '/service/pdu/:id',
        name: 'pdu',
        meta : {
          menuName : 'pdu', //lang.config.js 里面的语言键值
          rightPath : topMenuName + '.' + 'electricSystem.pdu',
          isMenu : true, 
          resourceUrl: 'main/service/KX/electricSystem/pdu/PduIndex',
        },
      }
    ]
  },
  //环境监测
  {
    path: '/service/environment',
    name: 'environmentSystem',    
    meta : {
      menuName : 'environment-monitoring', //lang.config.js 里面的语言键值
      rightPath : topMenuName + '.' + 'environmentSystem',
      isMenu : true, 
      resourceUrl: 'main/service/KX/environmentSystem/environmentSystemView',
      roles : ['platform_admin','merchant']
    },
    children : [
      //环境监测-烟感
      {
        path: '/service/smoge/:id',
        name: 'smoge',
        meta : {
          menuName : 'smoke-detector', //lang.config.js 里面的语言键值
          rightPath : topMenuName + '.' + 'environmentSystem.smoge',
          isMenu : true, 
          resourceUrl: 'main/service/KX/environmentSystem/env-smoge/envSmogeView'
        },
      },
      //环境监测-水浸
      {
        path: '/service/water/:id',
        name: 'water',
        meta : {
          menuName : 'water-logging', //lang.config.js 里面的语言键值
          rightPath : topMenuName + '.' + 'environmentSystem.water',
          isMenu : true, 
          resourceUrl: 'main/service/KX/environmentSystem/env-water/envWaterView'
        },
      },
      //环境监测-温湿度
      {
        path: '/service/temperature/:id',
        name: 'temperature',
        meta : {
          menuName : 'humiture', //lang.config.js 里面的语言键值
          rightPath : topMenuName + '.' + 'environmentSystem.temperature',
          isMenu : true, 
          resourceUrl: 'main/service/KX/environmentSystem/env-temperature/envTemperatureView'
        },
      },
      //环境监测-门禁
      {
        path: '/service/access/:id',
        name: 'access',
        meta : {
          menuName : 'doorAccess', //lang.config.js 里面的语言键值
          rightPath : topMenuName + '.' + 'environmentSystem.access',
          isMenu : true,
          resourceUrl: 'main/service/KX/environmentSystem/env-access/envAccessView'
        },
      }
    ]
  },
  //空调系统
  {
    path: '/service/airConditioner',
    name: 'airConditionerSystem',    
    meta : {
      menuName : 'air-conditioning-system', //lang.config.js 里面的语言键值
      rightPath : topMenuName + '.' + 'airConditionerSystem',
      isMenu : true, 
      resourceUrl: 'main/service/KX/airConditionerSystem/airConditionerSystemView',
    },
    children : [
       //空调系统-水泵
       {
        path: '/service/acwater/:id',
        name: 'acwater',
        meta : {
          menuName : 'water-pump', //lang.config.js 里面的语言键值
          rightPath : topMenuName + '.' + 'airConditionerSystem.acwater',
          isMenu : true, 
          resourceUrl: 'main/service/KX/airConditionerSystem/ac-water/acWaterView',
        },
      },
      //空调系统-风机
      {
        path: '/service/acfun/:id',
        name: 'acfun',
        meta : {
          menuName : 'draught-fan', //lang.config.js 里面的语言键值
          rightPath : topMenuName + '.' + 'airConditionerSystem.acfun',
          isMenu : true, 
          resourceUrl: 'main/service/KX/airConditionerSystem/ac-fan/acFanView',
        },
      }
    ]
  },
];

export default serviceRouters;