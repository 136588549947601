import { GetterTree } from 'vuex';
import { RootState } from '@/store/main/state-models';
const getters: GetterTree<RootState, any> = {
    /**
     * 用户信息
     * @param state 
     * @returns 
     */
    userInfo: (state) => {
        return state.userInfo
    },
    /**
     * 是否是管理员角色
     * @param state 
     * @param 平台管理员 platform_admin  商户 merchant 商户下的管理员 merchant_admin  商户下的访客 merchant_guest
     * @returns 
     */
    isAdmin : (state) =>{
        const roles = ['platform_admin','merchant'];
        return state.userInfo && roles.includes(state.userInfo.role);
    },
     /**
     * 是否是平台管理员
     * @param state
     * @returns 
     */
      isPlatformAdmin : (state) =>{
        const roles = ['platform_admin'];
        return state.userInfo && roles.includes(state.userInfo.role);
    },
    /**
     * 语言
     * @param state 
     * @returns 
     */
    lang: (state) => {
        return state.lang
    },
    /**
     * 服务器-矿箱列表
     * @param state 
     * @returns 
     */
     serviceOreBoxList: (state)=> {
        return state.serviceOreBoxList
    },
    /**
     * 通知未读数量
     * @param state 
     * @returns 
     */
    unreadNum : (state)=> {
        return state.unread;
    },
    /**
     * 是否显示页面加载中
     * @param state 
     * @returns 
     */
    isLoading : state => {
        return state.isLoading;
    },
    /**
     * 路由列表
     * @param state 
     * @returns 
     */
    routers : state =>{
        return state.routers
    },
    /**
     * 公共字典
     * @param state 
     * @returns 
     */
     tssPubDataDict : state =>{
        return state.tssPubDataDict
    },
};
export default getters;
