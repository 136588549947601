import { AxiosRequestConfig } from 'axios';
import Vue from 'vue';
import QS from 'querystring';
import {Interceptors} from './interceptors';

export default class BaseAPI {

  public axios: any;
  public $message = Vue.prototype.$Message;

  constructor() {
    // 获取axios实例
    this.axios = new Interceptors().getInterceptors();
  }

  /**
   * POST 请求
   * @param method  方法名
   * @param data 数据
   * @param contentType  内容类型
   * @param inParams 是否使用Params模式
   * @param tokenFlag  是否token校验
   */
  public post(method: string, data?: any,contentType?:string, inParams?: boolean, tokenFlag?:boolean, showLoading?:boolean ) {
   
    //body内容 如果contentType=form形式，用qs.stringify()将对象 序列化成URL的形式，以&进行拼接
    const repostData = contentType==='form' ? QS.stringify(data) : data;
    const responseType = method.indexOf('export')!==-1 ? 'blob' : '';//导出接口
    //param参数
    const params: any = getHead(contentType,tokenFlag,showLoading,responseType);
    if (inParams) {
      params.params = data;
    }
   
    return new Promise((resolve, reject) => {
      this.axios.post(method, repostData, params).then((res:any) => {
        this.resultHandle(res, resolve,reject,responseType);
      }).catch((err:any) => {
        reject(err.message);
      });
    });

  }

  /**
   * get 请求
   * @param method  方法名
   * @param data 数据
   * @param tokenHead 请求头是否加入token
   */
   public get(method: string, data?: any, tokenFlag?: boolean) {
   
    //param参数
    const params: any = {
      ...getHead('form',tokenFlag),
      params: data,
    };

    return new Promise((resolve, reject) => {
      this.axios.get(method, params).then((res:any) => {
        this.resultHandle(res, resolve,reject);
      }).catch((err:any) => {
        reject(err.message);
      });
    });

  }

  /**
   * 文件上传
   * @param method 方法名称 
   * @param data 数据
   */
  public uploadFile(method?: any, data?: any){
    //param参数
    const params = new FormData();
    params.append("file", data);

    return new Promise((resolve, reject) => {
      this.axios.post(method, params,{timeout: 1000 * 60 * 60}).then((res:any) => {
        this.resultHandle(res, resolve,reject);
      }).catch((err:any) => {
        reject(err.message);
      });
    });

  }

  /**
   * 处理返回结果
   * @param res 
   * @param resolve 
   */
   public resultHandle(res: any, resolve:any,reject?:any,responseType?:string) {
    if (res.code === '0') {
      resolve(res.data);
    } else if(res &&responseType==='blob'){
      resolve(res);
    } else {
      //服务端状态处理,例如中断性异常,退出异常等等
      this.$message.error(res.msg || '请求失败');
      reject()
    }
  }

}

/**
 * 设置请求头
 * @param tokenFlag 是否携带token
 * @param contentType 内容类型 body\form\file
 */
 function getHead(contentType = 'body',tokenFlag = false,showLoading = true,responseType?:string ): AxiosRequestConfig {  
  
  let requestConfig: AxiosRequestConfig = {};
  let tokenConfig = {};
  let fileConfig = {};

  if (tokenFlag) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const token = JSON.parse(sessionStorage.getItem('token')!);
    tokenConfig = {
      'Authorization': 'Bearer ' + token,
    };
  }

  if (contentType === 'file') {
    fileConfig = { "Content-Type": "multipart/form-data" };
  } else if(contentType === 'form') {
    fileConfig = { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" };
  } else {
    fileConfig = { "Content-Type": "application/json" };
  }

  requestConfig = {
    // 请求头参数
    headers: {
      ...tokenConfig,
      ...fileConfig,
      showLoading : showLoading,
      responseType ,
    },
  };
  return requestConfig;
  
}
