import { ActionTree } from 'vuex';
import { RootState } from '@/store/main/state-models';
import SysAPI from "@/api/sys/index";
import { resetRouter } from '@/router/index';
import serviceRouter from '@/router/service-router';
import baseRouter from '@/router/base-router';
const actions: ActionTree<RootState, any> = {
    /**
     * 服务器-矿箱列表
     */
    async queryOreBoxs({ state,commit}) {
        let data:any = [];
        const resFun:any = await SysAPI.queryMyGteway()
        data = resFun || []; 
        //显示在线的矿箱 1在线 0离线
        data = data.filter((item:any)=>item.state===1);
        commit('changeOreBoxList', data);


        // 父级菜单的menuname
        const topMenuName = 'service';
        //主路由
        const allRouters =  JSON.parse(JSON.stringify(baseRouter));
        //动态路由
        const newRouters:any[] = [];

        data.map((item:any,index:number)=>{

            item.name = item.name || ('Module box' + index+1);

            const subRouter = JSON.parse(JSON.stringify(serviceRouter));

            for(let i=0;i<subRouter.length;i++){

                //重置name
                subRouter[i].name = subRouter[i].name + '_' + item.no;
                //重置rightPath 格式：父级name.子级name
                if (subRouter[i].meta && subRouter[i].meta.rightPath){
                    //展开菜单 一级.二级
                    subRouter[i].meta.rightPath = topMenuName + '.' + item.no + '.' + subRouter[i].name;
                }    

                if(subRouter[i].children && subRouter[i].children.length){
                    const _subChild = subRouter[i].children;
                    for(let k =0;k<_subChild.length;k++){
                        _subChild[k].name =_subChild[k].name + '_' + item.no;
                        // _subChild[k].path = _subChild[k].path.replace(/\/:id/g,'/'+item.no);
                        if (_subChild[k].meta && _subChild[k].meta.rightPath){
                            //展开菜单 一级.二级.三级
                            _subChild[k].meta.rightPath = topMenuName + '.' + item.no + '.' + subRouter[i].name + '.' + _subChild[k].name;
                        }
                    }
                }
            }
            newRouters.push({
                name: item.no,
                path : item.no,
                meta : {
                    menuName : item.name || item.no.slice(0,5), //矿箱名称
                    rightPath : topMenuName + '.' + item.no,
                    isMenu : true, 
                    resourceUrl : 'main/service/KX/KxView',
                },                    
                children : subRouter
            })

        })

        if(allRouters && allRouters.length){
            allRouters[1].children.push(...newRouters);
            const saveRouter = getMenusForRoles(allRouters,state.userInfo?.role)
            commit('changeRouters', saveRouter);
            resetRouter();
        }
        
    },

    /**
     * 字典
     */
    getDict({commit}){
        return new Promise((resolve:any,reject:any)=>{
            SysAPI.getDict().then((data:any)=>{
                commit('setDict', data);
                resolve();
            }).catch(()=>{
                reject();
            })            
        })    
    },

    /**
     * 设置语言
     */
    setLocal({commit},lang:string){
        return new Promise((resolve:any,reject:any)=>{
            SysAPI.setLocale({locale:lang}).then((data:any)=>{
                commit('setLang', data);
                setTimeout(()=>{
                    resolve();
                },3000);                
            }).catch(()=>{
                reject();
            })            
        })        
    }
};
export default actions;

function getMenusForRoles(tree:any,userRole:any) {
  const arr:any[] = [];  
  tree.forEach((item:any) => {    
    if (!item.meta ||  !item.meta.roles || item.meta.roles.includes(userRole)) {
      arr.push({
        ...item,
        children: item.children ? getMenusForRoles(item.children, userRole) : null
      });
    }
  });
  return arr;
}