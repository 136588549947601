import { RootState } from '@/store/main/state-models';
//语音
const lang = localStorage.getItem('lang');
//用户信息
const userInfo = sessionStorage.getItem('userInfo');
//通知未读数量
const unread = sessionStorage.getItem('unread');
//矿箱列表
const serviceOreBoxList = localStorage.getItem('serviceOreBoxList');
//路由
const routers = localStorage.getItem('routers');
//字典
const tssPubDataDict = sessionStorage.getItem('tssPubDataDict');

const state: RootState = {
    lang: lang == null ? 'en_US' : lang,
    userInfo: userInfo == null ? null : JSON.parse(userInfo),
    serviceOreBoxList: serviceOreBoxList == null ? [] : JSON.parse(serviceOreBoxList),
    unread: unread == null ? 0 : unread,    
    promisings : 0,
    isLoading : false,
    routers : routers == null ? [] : JSON.parse(routers),
    tssPubDataDict : tssPubDataDict == null ? null : JSON.parse(tssPubDataDict),
};
export default state;
