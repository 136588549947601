import Vue from 'vue';
import ViewUI from 'view-design';
import VueI18n from 'vue-i18n';
import view_zh from 'view-design/dist/locale/zh-CN';
import view_us from 'view-design/dist/locale/en-US';
import zh_CN from './lang/zh_CN.json';
import en_US from './lang/en_US.json';
import store from '@/store';

Vue.use(VueI18n);
const messages = {
    'zh_CN': Object.assign(zh_CN, view_zh),
    'en_US': Object.assign(en_US, view_us),
};
const i18n = new VueI18n({
    locale: store.state.lang || 'en_US',
    messages,
    silentTranslationWarn: true,
});

Vue.use(ViewUI, {
    i18n: (key: any, value: any) => i18n.t(key, value),
});

export default i18n;
