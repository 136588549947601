import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/store/main/state';
import mutations from '@/store/main/mutations';
import getters from '@/store/main/getters';
import actions from '@/store/main/actions';

Vue.use(Vuex);


export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
});
