/**
 * 默认页面路由名称
 */
export default {
    /**
     * 登录页面
     */
    LOGIN_ROUTE_NAME: 'login',
    /**
     * 主页面
     */
    HOME_ROUTE_NAME: 'main',
};