/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import i18n from '@/i18n/language';
import { DirectiveOptions } from "vue";

Vue.config.productionTip = false
Vue.use(ViewUI);

//使用自定义指令
import *  as directive from '@/directive';
Object.keys(directive).forEach(key => {
  Vue.directive(key, (directive as { [key: string]: DirectiveOptions })[key]); 
})

//注册过滤器 存在数据更新问题，改用组件局部filters
// import * as filters from '@/filters/index';
// Object.keys(filters).forEach(key => {
//     Vue.filter(key, filters[key]);
// });

//获取字典对应的名称
Vue.filter('getName',function(value:any,list:any){
    list = list || [];
    const find = list.find((item:any)=>item.key===value);
    return find ? find.value : (value || '-');
})

/**
* 获取input值
* @param event
* @param obj 所属对象
* @param type 不传代表字符串，number代表数字
* @param precision 精度
*/
function getKeyValue(event:any,obj:any,key:string,type:string,precision:number){  
  if (type==='number') {
    const max = 99999999;//允许输入的最大数
    if (precision===0 || precision===undefined) {
      //整数
      obj[key] = parseInt(event.target.value) || '';  
      event.target.value = obj[key]
    } else {      
      //浮点数
      if (event.target.value.indexOf('.')!==-1) {
        const _arr = event.target.value.split('.');
        if (_arr[1]===''){
          //当输入的字符是小数点时，不做处理
          obj[key] = event.target.value;
        } else {
          //转成浮点数并设置精度
          obj[key] = parseFloat(event.target.value) || '';          
          const _lastArr = obj[key].toString().split('.');
          if(precision && _lastArr[1] && _lastArr[1].length>precision){
            _lastArr[1] = _lastArr[1].slice(0,precision); 
            obj[key] = parseFloat(_lastArr.join('.'));
          }
        }
      } else {
        obj[key] = parseInt(event.target.value) || '';
      }
    }
    obj[key] = obj[key] > max ? max : obj[key];
    event.target.value = obj[key]
  } else {
    obj[key] = event.target.value;
  }   
}
Vue.prototype.$getKeyValue = getKeyValue;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
