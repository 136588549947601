import { DirectiveOptions } from 'vue'

/** 盒子模型 
 * 作用：给div添加内阴影、且插入四个子元素
 * 用法: v-box
*/
export const box: DirectiveOptions = {
    inserted(el) {

        //给当前元素设置样式
        el.style.position = "relative"; 
        el.style.boxShadow = "0px 0px 30px 0px #2e346c inset";
        // el.style.minHeight = "80px"; 
        el.style.backgroundColor = "#0d1446";
        el.style.border = "1px solid #1e376b";

        const comCss = `
            position : absolute;
            width:14px;
            height:14px;
            z-index:999;
        `
        const borderCss = '1px solid #00fcfd';

        //左上角插入一个容器
        const letfTopSpan = document.createElement('span');
        letfTopSpan.style.cssText = `
            ${comCss}
            left:0;
            top:0;
            border-left: ${borderCss};
            border-top: ${borderCss};
        `
        el.appendChild(letfTopSpan);

        //右上角插入一个容器
        const rightTopSpan = document.createElement('span');
        rightTopSpan.style.cssText = `
            ${comCss}
            right:0;
            top:0;
            border-right: ${borderCss};
            border-top: ${borderCss};
        `
        el.appendChild(rightTopSpan);

        //左下角插入一个容器
        const leftBotSpan = document.createElement('span');
        leftBotSpan.style.cssText = `
            ${comCss}
            left:0;
            bottom:0;
            border-left: ${borderCss};
            border-bottom: ${borderCss};
        `
        el.appendChild(leftBotSpan);
        // 设置新容器的文字
        // leftBotSpan.src = require('@/assets/img/logo.png');

        //右下角插入一个容器
        const rightBotSpan = document.createElement('span');
        rightBotSpan.style.cssText = `
            ${comCss}
            right:0;
            bottom:0;
            border-right: ${borderCss};
            border-bottom: ${borderCss};
        `
        el.appendChild(rightBotSpan);
    }
};

/** 
 * 标题
 * 用法: v-title
*/
export const title:DirectiveOptions = {
    inserted(el) {
        //给当前元素设置样式
        el.style.fontSize = "18px"; 
        el.style.color = "#00E8EE";
        el.style.padding = "8px 15px";
    }
}

/**
 * 设置高度
 * 用法: v-height
 */
 export const height:DirectiveOptions = {
    inserted(el,node) {
        const height = node.value || '100';
        //给当前元素设置样式
        el.style.height = height + 'px'; 
        el.style.overflow = 'hidden'; 
    }
}